import './App.css';
import AuthContext from "./context/AuthProvider";
import {useContext, useEffect, useState} from "react";
import {Button, Container, Form, Modal} from "react-bootstrap";
import Registration from "./Registration";
import log, {logFlush} from "./Logger";
import axios from "axios";
import {SERVER_URL} from "./Config";
import ShowRegisterComplete from "./components/ShowRegisterComplete";
import CommercialPage from "./components/CommercialPage";
import UserReport from "./components/reports/UserReport";
import MovesReport from "./components/reports/MovesReport";
import PreFullReport from "./components/reports/PreFullReport";
import FullReport from "./components/reports/FullReport";
import IntegralReport from "./components/reports/IntegralReport";
import ChartsReport from "./components/reports/ChartsReport";
import Navigation from "./Navigation";
import Admin from "./components/Admin";
import AdminSub from "./components/AdminSub";
import Instruction from "./Instruction";
import Thanks from "./components/Thanks";
import ChapterSelect from "./components/ChapterSelect";
import Login from "./Login";
import AllChartsReport from "./components/reports/AllChartsReport";
import PointTask from "./components/PointTask";

function App() {
    const { auth, task, point, setPoint, registrationComplete, problems} = useContext(AuthContext);
    const [ focused, setFocused] = useState(false);
    const [ pause, setPaused] = useState(false);

    const [ showBugReport, setShowBugReport] = useState(false);
    const [ bugReport, setBugReport ] = useState('');
    const handleBugReport = event => {
        setBugReport(event.target.value);
    }

    async function sendBugReport()
    {
        await axios.post(SERVER_URL + '/api/bug_report.php',
            {
                message:bugReport,
                state: {
                    auth,
                    task
                }
            }
        );
        log('sendReport(' + bugReport + ')');
        setBugReport('');
        setShowBugReport(false);
    }

    const queryString = window.location.search;
    let typeRes = null;
    let repId = null;
    let prevIdRes = queryString.match(/\?report_([^=]+)=(.*)/);
    if (prevIdRes)
    {
        typeRes = prevIdRes[1];
        repId = prevIdRes[2];
        auth.status = 128;
    }

    let oldFormatRes = queryString.match(/\?report=(.*)/);
    if (oldFormatRes) {
        typeRes = 'short';
        repId  = oldFormatRes[1];
        auth.status = 128;
    }

    const [ typeReport, setTypeReport ] = useState(typeRes);
    const [ previewId, setPreviewId] = useState(repId);

    const onFocus = () => {
        setFocused(true);
        log('focused window');
    };

    const onBlur = () => {
        setFocused(false);
        log('leave window');
    };

    useEffect(() => {
        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        // Calls onFocus when the window first loads
        onFocus();
        fetchData();

        if (auth.status)
        {
            log('version 3.2');
        }

        window.onPointMachineBeforeResetState = (statePoint) =>
        {
            if (!statePoint)
            {
                return;
            }

            let pointsHistory = point.pointsHistory;
            if (!pointsHistory)
            {
                pointsHistory = [];
            }
            pointsHistory.push(statePoint);
            setPoint({...point, pointsHistory})
        }

        // Specify how to clean up after this effect:
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    useEffect(() => {
        const handleTabClose = event => {
           log('window closed');
           logFlush();
        };
        window.addEventListener('beforeunload', handleTabClose);
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, []);

    const handleClick = () => {
        setPaused(!pause);

        log('setPause (' + pause + ')');

        if (pause) {
            document.body.classList.remove('noScroll');
        } else {
            document.body.classList.add('noScroll');
        }
    }

    const [loaded, setLoaded] = useState(false);
    const [inviteData, setInviteData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/invites.php');
        setInviteData(response.data);
        setLoaded(true);
    }

    function checkInvites() {
        return inviteData.find(inv => {
            if (new Date(inv.start) - new Date() > 0 || new Date(inv.end) - new Date() <= 0) {
                return false;
            }

            const reg = new RegExp('\\?' + inv.invite,'g');
            return window.location.href.match(reg);
        });
    }

  //auth.status = 32;
  return (
      <>
          {registrationComplete ?
              <ShowRegisterComplete></ShowRegisterComplete>
              :
              <>
                  {pause && <Container className={'on-top top-50 start-50  translate-middle d-flex align-items-center justify-content-center'}>
                      &nbsp;
                  </Container>}
                    <div className="App">
                        {
                            (checkInvites() && !auth.accessToken) ? <>
                                    {checkInvites().commercial === '1' ?
                                        <CommercialPage invite={checkInvites().invite}></CommercialPage>
                                        :
                                        <Registration></Registration>
                                    }
                                </> :
                                <>
                                    {parseInt(auth.status) === 128 &&
                                        <>
                                            {typeReport === 'short' && <UserReport id={previewId}></UserReport>}
                                            {typeReport === 'moves' && <MovesReport id={previewId}></MovesReport>}
                                            {typeReport === 'methods' && <PreFullReport id={previewId}></PreFullReport>}
                                            {typeReport === 'full' && <FullReport id={previewId}></FullReport>}
                                            {typeReport === 'int' && <IntegralReport id={previewId}></IntegralReport>}
                                            {typeReport === 'charts' && <ChartsReport id={previewId}></ChartsReport>}
                                            {typeReport === 'allcharts' && <AllChartsReport id={previewId}></AllChartsReport>}
                                        </>
                                    }
                                    {parseInt(auth.status) === 16 &&
                                        <>
                                            {<Admin></Admin>}
                                        </>
                                    }
                                    {parseInt(auth.status) === 8 &&
                                        <>
                                            {<AdminSub></AdminSub>}
                                        </>
                                    }
                                    {
                                        auth.status < 16 && auth.accessToken ?
                                            (
                                                <>
                                                    <Navigation></Navigation>
                                                    <Container>
                                                        {
                                                            task && task.page === 0 && (task.number === 0 || task.number === 1) ? <Instruction></Instruction> : ''
                                                        }
                                                        {
                                                            task && task.page === 0 && (task.number === 2 || task.number === 3 || task.number === 4 || task.number === 5)  && task.type === 0 ? <PointTask></PointTask> : ''
                                                        }
                                                        {/* {
                                                            task && task.page > 0 && task.page <= problems.length + 1 && task.type === 1 ? <PointTask></PointTask> : ''
                                                        } */}
                                                        {
                                                            task && task.page > 0 && task.page <= problems.length + 1 && !task.lessonDone ? <ChapterSelect></ChapterSelect> : ''
                                                            
                                                        }
                                                        {
                                                            task && task.lessonDone ? <Thanks></Thanks> : ''
                                                        }
                                                    </Container>

                                                    <div className={'position-fixed bottom-0 end-0 me-3 mb-3 on-super-top'}>
                                                        <div>
                                                            <Button
                                                                title={'Сообщить о проблеме'}
                                                                className={'on-top-btn btn-info rounded-circle btn-pause mb-1'}
                                                                onClick={()=>setShowBugReport(!showBugReport)}>
                                                                <img src={ "/bug.png" }/>
                                                            </Button>
                                                        </div>

                                                        <div>
                                                            <Button
                                                                title={'Пауза'}
                                                                className={'on-top-btn btn-info rounded-circle btn-' + (pause ? 'continue' : 'pause')}
                                                                onClick={handleClick}>
                                                                <img src={pause ? "/play.png" : "/pause.png"}/>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    { !auth.status &&
                                                        <header className="App-header">
                                                            <Container className={"text-center"}>
                                                                <h1>Автоматизированные кейсы для тренировки психодиагностического мышления "ТОЧКА" </h1>
                                                            </Container>
                                                            
                                                            {/* <Container className={"text-start"}>
                                                                <h1>Автоматизированные кейсы для тренировки психодиагностического мышления</h1>
                                                                <p><b>Ануфриев А.Ф.</b> доктор психологических наук, профессор, профессор кафедры психологии труда и психологического консультирования Московского государственного педагогического университета.</p>
                                                                <p><b>Чмель В.И</b> преподаватель центра дополнительного образования, МГТУ им. Н.Э. Баумана</p>
                                                                <Container>
                                                                    <p>1. Целевая аудитория ОП (образовательного продукта): психологи, педагоги-психологи, педагоги, дефектологи, специалисты кадровых служб и агентств, а также  все желающие развивать свои интеллектуальные способности.</p>
                                                                    <p>2. Цель  ОП:</p>
                                                                    <Container>
                                                                        <p>• Накопление личного опыта  решения причинных психодиагностических задач как  необходимой составляющей диагностического мышления,</p>
                                                                        <p>• развитие способности мыслить причинно-следственными  связями</p>
                                                                    </Container>
                                                                    <p>3. Уникальность ОП состоит в том, что:</p>
                                                                    <p>3.1. Накопление опыта решения диагностических задач осуществляется с помощью  специального устройства – автоматизированной компьютерной системы образовательных кейсов, которые представляют собой реальные случаи из консультативной диагностической практики педагога-психолога.</p>
                                                                    <p>4. Результаты тренировки. Благодаря накоплению личного опыта диагностического мышления пользователи будут уметь:</p>
                                                                    <Container>
                                                                        <p>• анализировать запрос на проведение диагностического обследования,</p>
                                                                        <p>• выдвигать и обосновывать диагностические гипотезы,</p>
                                                                        <p>• проверять гипотезы с помощью применения диагностических методик, данных наблюдения, данных анамнеза,</p>
                                                                        <p>• преодолевать психологические барьеры, неизбежно возникающие при осуществлении диагностической деятельности,</p>
                                                                        <p>• строить диагностическое заключение и  оценивать его правильность.</p>
                                                                    </Container>
                                                                </Container>
                                                                <p>Развитие способности мыслить причинно-следственными  связями позволит также:</p>
                                                                <Container>
                                                                    <p>• осмысливать и понимать причины событий в  жизни других людей и в  своей жизни,</p>
                                                                    <p>◦ планировать  свои действия,</p>
                                                                    <p>◦ обретать  социальную успешность.</p>
                                                                </Container>
                                                            </Container> */}
                                                            
                                                            {parseInt(auth.status) != 128 && <Login/> }
                                                            <Container>&nbsp;</Container>
                                                        </header>
                                                    }
                                                </>
                                            )
                                    }
                                </>
                        }
                    </div>
              </>
        }
          <Modal
              show={showBugReport}
              onHide={() => {setShowBugReport(false); }}
              backdrop="static"
              keyboard={false}
              centered
              className={'on-super-top'}
          >
              <Modal.Header closeButton>
                  <Modal.Title>Сообщить о проблеме</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Form.Group className="mb-3">
                      <Form.Label>Опишите проблему</Form.Label>
                      <Form.Control as="textarea" rows={5} id={'ad_fenom_const'}  onChange={handleBugReport} value={bugReport} />
                  </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="danger" onClick={sendBugReport}>Отправить</Button>
                  <Button variant="secondary" onClick={() => {setShowBugReport(false);}}>
                      Отмена
                  </Button>
              </Modal.Footer>
          </Modal>
      </>
  );
}

export default App;
