import TotalTime from "./common/TotalTime";
import Problems from "./common/Problems";
import ResultSolve from "./common/ResultSolve";
import ViewInstruction from "./short/ViewInstruction";
import Fenomen from "./short/Fenomen";
// import PsyProblem from "./short/PsyProblem";
import Hypos from "./short/Hypos";
import DiagMethods from "./short/DiagMethods";
import DiagSummary from "./short/DiagSummary";
// import PsyDeterm from "./short/PsyDeterm";
import PredMerop from "./short/PredMerop";
import Katamnez from "./short/Katamnez";
import EventsKeeper from "./base/EventsKeeper";
import DiagRead from "./short/DiagRead";
// import ProblemSelected from "./short/ProblemSelected";
import HyposSelected from "./short/HyposSelected";
import DiagCheckSelected from "./short/DiagCheckSelected";
import HyposMax from "./short/HyposMax";
import DiagMax from "./short/DiagMax";

export default class AnalyticsShort
{
    constructor()
    {
        this.events = new EventsKeeper();
        this.problems = new Problems(this.events, () => this.beforeChangeProblem());

        this.processors = [
            new ViewInstruction(this.events),
            new Fenomen(this.events),
            // new PsyProblem(this.events),
            // new ProblemSelected(this.events),
            new Hypos(this.events),
            new HyposSelected(this.events),
            new HyposMax(this.events),
            new DiagMethods(this.events),
            new DiagRead(this.events),
            new DiagSummary(this.events),
            new DiagCheckSelected(this.events),
            new DiagMax(this.events),
            new ResultSolve(this.events),
            new TotalTime(this.events),
            // new PsyDeterm(this.events),
            new PredMerop(this.events),
            new Katamnez(this.events),
            //new Pause(this.events),
            this.problems
        ];
    }

    beforeChangeProblem()
    {
        this.processors.forEach(event => {
            if (event.changingProblem)
            {
                event.changingProblem();
            }
        });
    }

    fetch(data)
    {
        if (!data || !data.events)
        {
            return;
        }
        for (let event of data.events)
        {
            for (let processor of this.processors)
            {
                processor.tryToApply(event);
            }
        }
        this.beforeChangeProblem();
    }

    getProblems()
    {
        return this.problems.get();
    }
}
