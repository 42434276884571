import {Button, Container, Modal} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../context/AuthProvider";
import Form from "react-bootstrap/Form";
import PointView from "./task/point/PointView";
import PointsHistory from "./task/point/PointsHistory";
import log from "../Logger";

const PointTask = () => {
    const {auth, task, setTask, setPoint} = useContext(AuthContext);
    return (
        <Container>
            <Container>
                <h3>Демонстрационный режим</h3>
            </Container>
            {
                task.page === 0 && task.number === 2 && task.type === 0 ? 
                <Container>
                    <h5>Шаг 1</h5>
                    <iframe src="https://kinescope.io/embed/fWX1tj5Rpmjc7HewoXZe5x" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameborder="0" allowfullscreen width="100%" height="500"></iframe>
                        <Container>
                            <Button className={'float-end'} onClick={() => {
                                //log('Hypos, click apply');
                                setTask({...task, 
                                    id: 0,
                                    page: 0,
                                    chapter: 0,
                                    number: 3,
                                    type: 0});
                                }}>Дальше</Button>
                        </Container>
                </Container>
                :''
            }
            {    
                task.page === 0 && task.number === 3  && task.type === 0 ? 
                <Container className={'mt-5 row'}>
                    <Container className={'col-5'}>
                        <iframe src={'/point/?mode=0'} width={'100%'} height={'860px'} />
                    </Container>
                    <Container className={'col-7'}>
                        <h5>Шаг 2</h5>
                        <Container>
                            <Container>Результаты обработки (проставления точек)</Container>
                            <PointsHistory></PointsHistory>
                        </Container>
                    </Container>
                    <Container>
                        <Button className={'float-start'} onClick={() => {
                                //log('Hypos, click apply');
                                setTask({...task, number: 2, chapter: 0})
                                setPoint({
                                    pointsHistory: []
                                });
                            }}>Назад</Button>
                        <Button className={'float-end'} onClick={() => {
                            //log('Hypos, click apply');
                            setTask({...task, number: 4, chapter: 0});
                            setPoint({
                                pointsHistory: []
                            });
                        }}>Дальше</Button>
                    </Container>
                </Container>
                :''
            }
            {
                 task.page === 0 && task.number === 4 && task.type === 0 ? 
                 <Container>
                    <h5>Шаг 3</h5>
                    <iframe src="https://kinescope.io/embed/423ysUZcyKuGG2c7ap44Fj" allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameborder="0" allowfullscreen width="100%" height="500"></iframe>
                    <Container>
                        <Button className={'float-start'} onClick={() => {
                                //log('Hypos, click apply');
                                setTask({...task, number: 3, chapter: 0})
                                setPoint({
                                    pointsHistory: []
                                });
                            }}>Назад</Button>
                    </Container>
                    <Container>
                        <Button className={'float-end'} onClick={() => {
                            //log('Hypos, click apply');
                            setTask({...task, number: 5, chapter: 0});
                            setPoint({
                                pointsHistory: []
                            });
                        }}>Дальше</Button>
                    </Container>

                </Container>
                 :''
            }

            {
                task.page === 0 && task.number === 5 && task.type === 0 ?
                <Container className={'mt-5 row'}>
                    <Container className={'col-5'}>
                        <iframe src={'/point/?mode=' + 1} width={'100%'} height={'860px'} />
                    </Container>
                    <Container className={'col-7'}>
                        <h5>Шаг 4</h5>
                        {/*<Container>Гипотезы</Container>
                        <Container>
                            Гипотеза 1:
                            <Form.Control
                                type="text"
                                id="pointHypo"
                                placeholder='Введите гипотезу'
                            />
                        </Container>*/}
                        <Container>
                            <Container>Результаты обработки (проставления точек)</Container>
                            <PointsHistory></PointsHistory>
                        </Container>
                    </Container>
                    {/* <Container>
                        <Button className={'float-end'} onClick={() => {
                            //log('Hypos, click apply');
                            setTask({...task, chapter: 0})
                            setPoint({
                                pointsHistory: []
                            });
                        }}>Назад</Button>
                    </Container> */}
                    {/* <Container>
                        <Button className={'float-end'} onClick={() => {
                            //log('Hypos, click apply');
                            setTask({...task, chapter: 1});
                            setPoint({
                                pointsHistory: []
                            });
                        }}>Дальше</Button>
                    </Container> */}
                    <Container>
                        <Button className={'float-end'} onClick={() => {
                            //log('Hypos, click apply');
                            setTask({...task,
                                id: 1,
                                page: 1,
                                chapter: 0,
                                number: 1,
                                type: 0});
                        }}>Перейти к задачам</Button>
                    </Container>
                </Container>
                :''
            }

            
        </Container>
    );
}

export default PointTask
