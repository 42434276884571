import {Button, Container, Form, Modal} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AuthContext from "../../context/AuthProvider";
import log from "../../Logger";
import axios from "axios";
import {SERVER_URL} from "../../Config";

const Katamnez = () => {
    const { setTask, task, problems} = useContext(AuthContext);
    const [showWarning, setShowWarning] = useState(false);

    const [data, setData] = useState([]);
    async function fetchData() {
        const response = await axios.get(SERVER_URL + '/api/katamnez.php',
            { params: { id:task.id } }
        );
        setData(response.data);
    }
    useEffect(() => {
        fetchData();
    }, []);

    log('showed katamnez');

    return (
        <>
            <Container className={'text-success text-center'}>
                <h2>Диагноз подтвержден</h2>
            </Container>
            <Container>
                <h3 className={'mt-3'}>Правильный ответ:</h3>
                <Container className={'border lead'}>
                    {data.katamnez}
                </Container>
                <Container className={'my-4'}>
                    <h5>Задача решена! Теперь вы можете приступить к решению следующей.</h5>
                    <Button onClick={() => {
                        log('katamnez, click next problem');

                        let newPage = task.page + 1;
                        if (newPage > problems.length) {
                            newPage = task.page;
                            setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, type: 0, wrongAnswers:0, checkFinalDiagnos: false, lessonDone: true})
                            return;
                        }
                        setTask({page: newPage, id:problems[newPage - 1].id, lastTask: newPage, number: 1, chapter: 0, type: 0, wrongAnswers:0, checkFinalDiagnos: false})
                    }}>К следующей задаче</Button>
                </Container>
            </Container>

            <Modal
                show={showWarning}
                onHide={() => {setShowWarning(false); }}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Вы успешно решили задачу</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Вы уже не сможете вернуться к этой задаче. Спасибо Вам.</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {setShowWarning(false);}}>
                        Принято
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Katamnez
